const colorModeTransition = 'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)';

export default {
  colors: {
    text: '#5a5653',
    background: '#f6f5f4',
    accent: '#f9e8df',
    modes: {
      dark: {
        text: '#f7f7f7',
        background: '#333',
      },
    },
  },
  fonts: {
    body: 'Graphik Regular, sans-serif',
  },
  initialColorMode: 'light',
  colorModeTransition
}

